<template>
  <div id="nav">

      <v-app-bar dense elevation="8" prominent color="primary">

        <div class="my-auto">
          <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-md-none">
          </v-app-bar-nav-icon>
        </div>

        <v-app-bar-title class="my-auto text-no-wrap ml-5 ">
          <v-img :contain="true" max-height="50" max-width="150" src="..//assets/images/logo.png">
          </v-img>
        </v-app-bar-title>

        <v-tabs 
        class="d-none d-md-block"
        color="info" 
        fixed-tabs
        centered
        >
          <v-tabs-slider color="info"></v-tabs-slider>
          
          <v-tab v-if="isLoggedIn" to="/">
            Weekly Dashboard
          </v-tab>
          <v-tab v-if="isLoggedIn" to="/queries">
            Custom Queries
          </v-tab>
          <v-tab v-if="isLoggedIn" to="/history">
            My History
          </v-tab>

          <v-tab v-if="isLoggedIn" to="/logout">
            Logout
          </v-tab>

          <v-tab v-else to="/login">
            Login
          </v-tab>

        </v-tabs>

      </v-app-bar>

      <v-navigation-drawer 
        v-model="drawer" 
        absolute 
        temporary
        color="primary">
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">

          <v-list-item v-if="isLoggedIn">
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-desktop-mac-dashboard</v-icon>
            </v-list-item-icon>
            <router-link to="/" class="anchor">
            Weekly Dashboard
            </router-link>
          </v-list-item>

          <v-list-item v-if="isLoggedIn">
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-feature-search</v-icon>
            </v-list-item-icon>
            <router-link to="/queries" class="anchor">
            Custom Queries
            </router-link>
          </v-list-item>

          <v-list-item v-if="isLoggedIn">
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-clipboard-text-clock</v-icon>
            </v-list-item-icon>
            <router-link to="/history" class="anchor">
            History
            </router-link>
          </v-list-item>

          <v-list-item v-if="isLoggedIn"> 
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <router-link to="/logout" class="anchor">
            Logout
            </router-link>
          </v-list-item>


          <v-list-item v-else>
            <v-list-item-icon class="mr-menu">
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <router-link to="/login" class="anchor">
            Login
            </router-link>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    ...mapGetters(['isLoggedIn'])
  },
}
</script>