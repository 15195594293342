import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueSimpleAlert from "vue-simple-alert";

import { auth } from './lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';

Vue.config.productionTip = false

Vue.use(VueSimpleAlert);

Vue.filter('formatDate', function(value) {
  if (value) {
    if(typeof value != 'string'){
      return value.toDate().toLocaleDateString();
    } else {
      return value; 
    }
  }
});

Vue.filter('formatTime', function(value) {
  if (value) {
    if(typeof value != 'string'){
      return value.toDate().toLocaleTimeString();
    } else {
      return value; 
    }
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    if(typeof value != 'string'){
      return value.toDate().toLocaleString();
    } else {
      return value; 
    }
  }
});

Vue.filter('formatScore', function(value) {
  if (value && !isNaN(value)) {
    return parseInt(value * 100);
  }
});

Vue.filter('formatAuthors', function(value) {
  if (value && Array.isArray(value)) {
    return value.join(', ');
  } else {
    return value;
  }
});

Vue.filter('makeYesNo', function(boolean) {
  if(boolean === true) {
    return "Yes";
  } else { 
    return "NO";
  }
});

Vue.filter('withinLimit', function(was_too_long) {
  if (was_too_long === true) {
    return "No, only first 1000 articles were processed";
  } else {
    return "Yes";
  }
});

Vue.filter('formatDateRange', function(weeks, customDate) {
  if (isNaN(weeks)) {
    return "";
  } else if(customDate === true) {
    return 'custom range';
  } else {
    return weeks/52;
  }
});

// import global.css file:
import "@/assets/css/global.css"


let app; 
onAuthStateChanged(auth, (user) => {
  if(!app) {
    app = new Vue({
      vuetify,
      router,
      store, 
      render: h => h(App)
    }).$mount('#app')
  }
  if(user) {
    store.dispatch('fetchUserProfile');
  }
});

