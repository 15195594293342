import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserSessionPersistence } from 'firebase/auth';
//browserLocalPersistence
import { getFirestore, collection } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAEz-_Lf6qhnfXk8MhQRoXDX3CEdbqorxU",
  authDomain: "rctalert-lareb.firebaseapp.com",
  projectId: "rctalert-lareb",
  storageBucket: "rctalert-lareb.appspot.com",
  messagingSenderId: "1083969507748",
  appId: "1:1083969507748:web:889d7a3887f5debc413131"
};

initializeApp(firebaseConfig);

const auth = getAuth();
setPersistence(auth, browserSessionPersistence); 
//browserLocalPersistence

const db = getFirestore();

const researchCollection = collection(db, 'research');
const trackingCollection = collection(db, 'tracking');
const queriesCollection = collection(db, 'queries');
const queryResearchCollection = collection(db, 'queryResearch');

export { auth, researchCollection, trackingCollection, queriesCollection, queryResearchCollection }; 