import Vue from 'vue';
import Vuex from 'vuex';
import { auth, researchCollection, trackingCollection, queriesCollection, queryResearchCollection } from '../lib/firebase';
import { signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth';
import router from '../router/index';
import { query, where, getDocs, getDoc, setDoc, doc, orderBy, addDoc, deleteDoc} from "firebase/firestore";
import axios from 'axios'

Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    userProfile: {},
    loadingDocs: false,
    savingDocs: false,
    savingQueryDocs: false, 
    loadingQueries: false,
    loadingQueryDocs: false,
    docs: [],
    queries: [],
    currentQuery: {},
    queryDocs: [],
    currentWeekLastUpdate: null, 
    currentTimeout: null,
  },
  mutations: {
    setUserProfile(state, payload) {
      state.userProfile = payload;
    }, 
    setDocs(state, payload) {
      state.docs = payload;
    },
    setLoadingDocs(state, payload) {
      state.loadingDocs = payload;
    },
    setSavingDocs(state, payload) {
      state.savingDocs = payload;
    },
    setSavingQueryDocs(state, payload) {
      state.savingQueryDocs = payload;
    },
    setCurrentWeekLastUpdate(state, payload) {
      state.currentWeekLastUpdate = payload;
    }, 
    setQueries(state, payload) {
      state.queries = payload;
    },
    setQueryDocs(state, payload) {
      state.queryDocs = payload;
    }, 
    setLoadingQueries(state, payload) {
      state.loadingQueries = payload;
    }, 
    setLoadingQueryDocs(state, payload) {
      state.loadingQueryDocs = payload;
    },
    setCurrentQuery(state, payload) {
      state.currentQuery = payload;
    },
  }, 
  getters: {
    isLoggedIn: state => Boolean(state.userProfile && state.userProfile.uid)
  },
  actions: {
    async login({dispatch}, payload) {

      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .catch((error) => {
          console.log(error.message);
        })
        .then(() => {
          dispatch('fetchUserProfile');
          router.push('/').catch(err => console.log(err));
        })
    },
    async resetCurrentTimeout({dispatch}) {
      if (this.state.userProfile && this.state.userProfile.uid) {
        clearTimeout(this.state.currentTimeout);
        this.state.currentTimeout = setTimeout(() => {
          dispatch("logout");
        }, 2*60*60*1000);
      }
    },
    async logout({dispatch}) {
      await signOut(auth);
      dispatch('fetchUserProfile');
      router.push('/login');
    }, 
    async resetPassword(_, email) {

      sendPasswordResetEmail(auth, email);
      router.push('/reset');
    },
    async fetchUserProfile({commit}){

      commit('setUserProfile', (auth.currentUser && auth.currentUser.uid) ? auth.currentUser : null);
    },
    async loadCurrentWeekLastUpdate({commit}, {year, week}) {
      const trackingDocID = String(year) + String(week);

      const currentWeekLastUpdate = await getDoc(doc(trackingCollection, trackingDocID));
      if(currentWeekLastUpdate.exists()) {
        commit('setCurrentWeekLastUpdate', currentWeekLastUpdate.data().timestamp);
      } else{
        commit('setCurrentWeekLastUpdate', null);
      }
    },
    async loadDocs({commit, dispatch}, {year, week}) {
      commit('setLoadingDocs', true);

      await getDocs(query(researchCollection, where('year', '==', year), where('week', '==', week))) 
        .then(snapshot => {
          var docs = [];
          snapshot.forEach(doc => {
              if(!doc.data().score || doc.data().score > 0.6) {
              const curDoc = doc.data();
              curDoc.wasShown = true;
              docs.push(curDoc);
            }
          });

          dispatch('loadCurrentWeekLastUpdate', {year, week});

          commit('setDocs', docs);
          commit('setLoadingDocs', false);
        });
      
    }, 
    async saveSelected({commit}) {

      commit('setSavingDocs', true);

      let requests = this.state.docs.map((row) => {
        return new Promise((resolve) => {
          setDoc(doc(researchCollection, row.pmid), {selected: row.selected, wasShown: row.wasShown}, {merge: true})
            .then(() => resolve());
        });
      });
      
      Promise.all(requests).then(() => {
        commit('setSavingDocs', false);
      });
    },

    async saveSelectedQueryDocs({commit}) {

      commit('setSavingQueryDocs', true);

      let requests = this.state.queryDocs.map((row) => {

        const wasSelected = row.selected ? row.selected : false; 
        return new Promise((resolve) => {
          setDoc(doc(queryResearchCollection, row.pmid), {selected: wasSelected, wasShown: row.wasShown}, {merge: true})
            .then(() => resolve());
        });
      });
      
      Promise.all(requests).then(() => {
        commit('setSavingQueryDocs', false);
      });
    },

    async setLastUpdate({dispatch}, {year, week}) {
      const trackingDoc = String(year) + String(week);
      const dateOfUpdate = new Date();

      setDoc(doc(trackingCollection, trackingDoc), {year: year, week: week, timestamp: dateOfUpdate, user: this.state.userProfile.uid}, {merge: true});
      
      dispatch('loadCurrentWeekLastUpdate', {year, week});
    },

    async setLastUpdateQuery({dispatch}, {qid}) {

      const dateOfUpdate = new Date();

      setDoc(doc(queriesCollection, qid), {timeExported: dateOfUpdate, exportedByUser: this.state.userProfile.uid}, {merge: true});
      
      dispatch('loadCurrentQuery', qid);
    },

    async loadCurrentQuery({commit}, qid) {

      await getDoc(doc(queriesCollection, qid))
        .then(doc => {
          commit('setCurrentQuery', doc.data());
        });
    },

    async loadQueries({commit}) {
      commit('setLoadingQueries', true);

      await getDocs(query(queriesCollection, orderBy("timestamp", "desc"))) 
        .then(snapshot => {
          var docs = [];
          snapshot.forEach(doc => {
            const curDoc = doc.data();
            curDoc.id = doc.id;
            docs.push(curDoc);
          });

          commit('setQueries', docs);
          commit('setLoadingQueries', false);
        });
      
    }, 

    async submitQuery({commit, dispatch}, {query, weeks, customDate}) {
      commit('setLoadingQueries', true);

      const docRef = await addDoc(queriesCollection, 
                                      {query: query, 
                                      weeks: weeks,
                                      customDate: customDate,
                                      uid: this.state.userProfile.uid, 
                                      email: this.state.userProfile.email,
                                      timestamp: new Date(),
                                      pmids: [],
                                      was_too_long: false, 
                                      completed: false}, {merge: true});

      dispatch('loadQueries');
      dispatch('startAnalysis', {query: query, weeks: weeks, qid: docRef.id, email: this.state.userProfile.email, customDate: customDate});
        
    },

    async deleteSelectedQuery({commit, dispatch}, qid) {
      commit('setLoadingQueries', true);
      await deleteDoc(doc(queriesCollection, qid));
      dispatch('loadQueries');
    },

    async startAnalysis(_, {query, weeks, qid, email, customDate}) {
      axios
        .post(`https://lareb-api.rctalert.com/api/customQuery`, {  // 34.88.151.181
          query: query,
          weeks: weeks,
          qid: qid,
          email: email,
          customDate: customDate,
        })
        .then((response) => {
          return response;
        });
    },

    async loadQueryDocs({commit}, {qid}) {
      commit('setLoadingQueryDocs', true);

      const currentQuery = await getDoc(doc(queriesCollection, qid));
      const currentData = currentQuery.data();
      const pmids = currentData.pmids;

      commit('setCurrentQuery', currentData);

      const foundDocs = [];

      let requests = pmids.map((row) => {
        return new Promise((resolve) => {
          getDoc(doc(queryResearchCollection, row))
            .then((queryDoc) => {
              if(queryDoc.exists) {
                if(queryDoc.data()) {
                  const curDoc = queryDoc.data();

                  getDoc(doc(researchCollection, row))
                    .then((researchDoc) => {
                      if(researchDoc.exists && researchDoc.data()) {
                          const researchData = researchDoc.data(); 

                          if(researchData.selected) {
                            curDoc.selected = researchData.selected;
                          }
                      }
                      curDoc.wasShown = true; 
                      if(!curDoc.score || curDoc.score > 0.6 || curDoc.score == -1) {
                        foundDocs.push(curDoc);
                      }
                      resolve();
                    })

                  } else {
                    console.log("No data found for " + row);
                  }
                }
              // resolve();
            });
        });
      });
      
      Promise.all(requests).then(() => {
        commit('setQueryDocs', foundDocs);
        commit('setLoadingQueryDocs', false);
      });
      
    }, 

  }
});