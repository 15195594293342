<template>
<v-app>
  <div id="app">
    <Menu />
    <router-view />
  </div>
</v-app>
</template>

<script>
import Menu from './components/Menu.vue';
import {
  mapActions
} from "vuex";

export default {
  data: () => ({
    events: ['load', 'mousemove', 'mousedown','click', 'scroll', 'keypress']
  }),
  components: {
    Menu,
  },
  mounted() {
    this.resetCurrentTimeout();
    this.events.forEach(e => window.addEventListener(e, this.resetCurrentTimeout));
  },
  methods: {
    ...mapActions(["resetCurrentTimeout"]),
  },
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
</style>
