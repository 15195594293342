import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FEF1E6',
        secondary: '#ECF3FD',
        accent: '#F9D5A7',
        error: '#F0887D',
        info: '#90AACB',
        success: '#fff9f5',
        warning: '#4F4F4F',
        anchor: '#90AACB', 
        'v-progress-linear': '#90AACB',
      },
    },
  },
})

export default vuetify

